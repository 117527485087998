p {
  margin-bottom: 0px;
}

/* For modern browsers */
::-webkit-scrollbar {
  width: 5px; 
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 15px; 
}

::-webkit-scrollbar-thumb:hover {
  background: #818181; 
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 15px;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #a9a8a8 #f1f1f1;
}
