:root {
  --primary: black;
  --100: #cce5e5;
  --200: #99cbcb;
  --300: #66b0b0;
  --400: #339696;
  --500: #007c7c;
  --600: #006363;
  --700: #004a4a;
  --800: #003232;
  --900: #001919;

  --101: #ffdfcc;
  --201: #ffbf99;
  --301: #ff9f66;
  --401: #ff7f33;
  --501: #ff5f00;
  --601: #cc4c00;
  --701: #993900;
  --801: #662600;
  --901: #331300;
}

.date_pick_cont .MuiTextField-root {
  width: 100% !important;
}
